import React from 'react';
import { InternalError } from '../../errors';

export interface InnerNotFoundBoundaryProps {
  render: (error: InternalError) => React.ReactNode;
}
interface InnerNotFoundBoundaryState {
  error?: InternalError;
}

export class ErrorBoundary extends React.Component<InnerNotFoundBoundaryProps, InnerNotFoundBoundaryState> {
  state = {
    error: undefined,
  };
  constructor(props: $TSFixMe) {
    super(props);
  }

  componentDidCatch(error: Error) {
    if (error instanceof InternalError) {
      this.setState({
        error,
      });
    } else {
      throw error;
    }
  }

  render() {
    const { render, children } = this.props;
    const { error } = this.state;
    if (error) {
      return render(error);
    }
    return children;
  }
}
