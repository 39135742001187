import React from 'react';
import { Layout } from '../components/Layout';

function App() {
  return (
    <div className="App">
      <Layout>
      <section className="hero is-fullheight" style={{ background: '#fafafa' }}>
        <div className="hero-body">
          <div className="container has-text-centered">
            <h1 className="title is-size-5" style={{ marginBottom: '3rem' }}>
              警備員付き添いサービスは終了しました
            </h1>
            <h2 className="subtitle is-size-6">
              警備員付き添いサービスは
              <br />
              2021/06/30(水)にサービスの提供を
              <br />
              終了させていただきました。
            </h2>
            <a className="button is-inverted is-link" href="https://support.leafee.me/hc/ja/articles/900006271146" target="_blank">詳しくはこちら</a>
          </div>
        </div>
      </section>
    </Layout>
    </div>
  );
}
export default App;
