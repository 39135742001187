import React from 'react';
import {Link} from 'react-navi';

export function Forbidden() {
  return (
    <section className="hero is-fullheight" style={{ background: '#fafafa' }}>
      <div className="hero-body">
        <div className="container has-text-centered">
          <p className="has-text-warning" style={{ fontSize: 128, marginBottom: '3rem' }}>
            <i className="fas fa-exclamation-triangle" />
          </p>
          <h1 className="title is-size-5" style={{ marginBottom: '3rem' }}>
            このページは表示できません
          </h1>
          <h2 className="subtitle is-size-6">
              前のページに戻ってからもう一度お試しください。
          </h2>
            <Link href="/" className="button is-inverted is-link">トップに戻る</Link>
        </div>
      </div>
    </section>
  );
}
