import React from 'react';
import { constants } from '@leafee-bodyguard/core';
import BusyIndicator from 'react-busy-indicator';
import { Link, NotFoundBoundary, useLoadingRoute } from 'react-navi';
import { Forbidden } from './Forbidden';
import { InternalError } from './InternalError';
import { ErrorBoundary } from './boundaries/InternalErrorBoundary';

export function Layout({ children }: $TSFixMe) {
  let loadingRoute = useLoadingRoute();
  return (
    <>
      <BusyIndicator isBusy={!!loadingRoute} active={!!loadingRoute} delayMs={200} color="#1ee79e" className style />
      <main>
        <NotFoundBoundary render={Forbidden}>
          <ErrorBoundary render={InternalError}>{children || null}</ErrorBoundary>
        </NotFoundBoundary>
      </main>
    </>
  );
}
