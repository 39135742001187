import React from 'react';

export function InternalError() {
  return (
      <section className="hero is-fullheight" style={{ background: '#fafafa' }}>
          <div className="hero-body">
              <div className="container has-text-centered">
                  <p className="has-text-danger" style={{ fontSize: 128, marginBottom: '3rem' }}>
                      <i className="fas fa-times"></i>
                  </p>
                  <h1 className="title is-size-5" style={{ marginBottom: '3rem' }}>
                      エラーが発生しました
                  </h1>
                  <h2 className="subtitle is-size-6">
                      しばらく経ってからもう一度お試しください。
                  </h2>
              </div>
          </div>
      </section>
  );
}
